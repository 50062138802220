import { PropsWithTheme } from '@rategravity/own-up-component-library';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library-legacy';
import styled from 'styled-components';

export const Spacer = styled.div<{ backgroundColor?: string }>`
  padding-bottom: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(6.25)}px`};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const ResponsiveSpacerStyle = createOwnUpStyle({
  variants: {
    medium: {
      paddingBottom: 50
    },
    smallAndDown: {
      paddingBottom: 20
    }
  }
});

export const ResponsiveSpacer = createOwnUpComponent(OwnUpBox, ResponsiveSpacerStyle);
